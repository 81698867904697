// @import "@ifca-ui/neumorphism/src/styles/root.scss";
@import './styles/hotel.scss';
@import './styles/latecheckout.scss';
@import './styles/extendstay.scss';
@import './styles/bills.scss';
@import '../components//InstallBanner/installbanner.scss';
@import '~react-phone-input-2/lib/material.css';
.about-us-wrapper {
  .MuiAvatar-root {
    width: 80%;
    height: 80%;
    max-width: 520px;
    margin: auto;
    margin-bottom: 12px;
  }
  text-align: center;
}
.contact-input {
  .react-tel-input .special-label {
    color: #394d86 !important;
    background: transparent;
    margin-left: -26px;
  }
  .react-tel-input .form-control {
    background: transparent;
    border-color: transparent;
    color: #394d86 !important;
    border-bottom: 1px solid #0000006b !important;
    padding: 12px 18px 12px 50px;
  }
  .react-tel-input .country-list {
    max-height: 110px !important;
  }
  .react-tel-input .special-label {
    font-size: 12px;
  }
}
