.common-icon.MuiIconButton-root {
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  border-radius: 10px;
  margin: 6px 0px;
  width: 40px;
  height: 40px;
  padding: 0px;
  img {
    width: 25px;
    height: 25px;
  }
}
.common-secondary-icon.MuiIconButton-root {
  background: #ef4b4b !important;
  box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  border-radius: 10px;
  margin: 6px 0px;
  width: 40px;
  height: 40px;
  padding: 0px;
  img {
    width: 25px;
    height: 25px;
  }
}
